import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "vue-datetime/dist/vue-datetime.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./style.css";
import 'vue-slider-component/theme/default.css'
import "vue-select/dist/vue-select.css";
import { i18n } from "./plugins/i18n";

import { BootstrapVue } from "bootstrap-vue";
import VueCookies from "vue-cookies";
import { Datetime } from "vue-datetime";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import InfiniteLoading from "vue-infinite-loading";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueSlider from 'vue-slider-component'
import Vuelidate from "vuelidate";
import VueBottomDialog from 'vue-bottom-dialog'
import VueMoment from "vue-moment";
import vSelect from "vue-select";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faCog,
  faFileAlt,
  faStickyNote,
  faTimes,
  faArrowAltCircleRight,
  faFolderOpen,
  faInfoCircle,
  faPrint,
  faAngleRight,
  faArrowLeft,
  faEyeSlash,
  faEye,
  faSearch,
  faClock,
  faFileUpload,
  faTimesCircle,
  faFilePdf,
  faPlayCircle,
  faTruck,
  faMapMarkerAlt,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import liff from "@line/liff";

const moment = require("moment");
require("moment/locale/th");

Vue.use(InfiniteLoading, {});
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(VueMoment, { moment });
Vue.component("datetime", Datetime);
Vue.use(Vuelidate);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component('VueSlider', VueSlider)
Vue.component("v-select", vSelect);
library.add(faPlayCircle, faFilePdf, faTimesCircle, faFileUpload, faSearch, faChevronRight, 
  faClipboardList, faCog, faFileAlt, faStickyNote, faChevronDown, faChevronUp, faTimes, 
  faArrowAltCircleRight, faFolderOpen, faInfoCircle, faPrint, faAngleRight, faArrowLeft, 
  faEyeSlash, faEye, faClock, faTruck, faMapMarkerAlt, faCheckCircle);


Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY HH:mm";
Vue.prototype.$liff = liff;
Vue.use(VueBottomDialog)

// Choose MODE (UAT, DEV , PROD )
const MODE = process.env.VUE_APP_MODE ? `${process.env.VUE_APP_MODE}` : 'DEV';

if (MODE == "PROD") {
  Vue.prototype.$baseUrl = "/api";
  Vue.prototype.$lineRedirect = "";
  Vue.prototype.$liff_Main_ID = "";
  Vue.prototype.$policyUrl = "";
  //Vue.prototype.$lineOfficialAccount = ""
  Vue.prototype.$lineOfficialAccount = ""
}

if (MODE == "UAT") {
  Vue.prototype.$baseUrl = "https://uat-master-chatbot-message-api.d-dots.com/api";
  Vue.prototype.$lineRedirect = "";
  Vue.prototype.$liff_Main_ID = "1660973654-q6mM3v6m"; 
  Vue.prototype.$policyUrl = "";
  Vue.prototype.$lineOfficialAccount = "https://page.line.me/234jhmej"
}

if (MODE == "AOMPLEARN") {
  Vue.prototype.$baseUrl = "https://aomplearn-master-chatbot-message-api.d-dots.com/api";
  Vue.prototype.$lineRedirect = "";
  Vue.prototype.$liff_Main_ID = "1660973654-q6mM3v6m"; 
  Vue.prototype.$policyUrl = "";
  Vue.prototype.$lineOfficialAccount = "https://page.line.me/234jhmej"
}

if (MODE == "DEV") {
  Vue.prototype.$baseUrl = "https://dev-master-chatbot-message-api.d-dots.com/api";
  Vue.prototype.$lineRedirect = "";
  Vue.prototype.$liff_Main_ID = "1660973654-q6mM3v6m"; 
  Vue.prototype.$policyUrl = "";
  Vue.prototype.$lineOfficialAccount = "https://page.line.me/234jhmej"
}

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers = {
      language: VueCookies.get("language")
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  //set cookie beforeEach
  let cookiesDomain = window.location.hostname;
  if (to.params.lang) {
    VueCookies.set("language", to.params.lang, 60 * 60 * 24 * 365, "/", cookiesDomain.replace("www.", ""))
    i18n.locale = to.params.lang
  } else {
    VueCookies.set("language", "th", 60 * 60 * 24 * 365, "/", cookiesDomain.replace("www.", ""))
    i18n.locale = "th"
  }
  next();
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
